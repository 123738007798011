import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use"

export default class extends Controller {
  static values = {
    autoplay: Boolean
  }
  static targets = ["mediaChrome", "video"]

  connect() {
    useIntersection(this)
  }

  appear() {
    if (this.autoplayValue && this.hasVideoTarget) {
      this.videoTarget.play()
    }
  }

  disappear() {
    if (this.autoplayValue && this.hasVideoTarget) {
      this.videoTarget.pause()
    }
  }
}
