import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["overflowTop", "overflowBottom"]
  static targets = ["topSentinel", "bottomSentinel"]

  connect() {
    this.createSentinels()
    this.#setupIntersectionObserver()
  }

  disconnect() {
    this.observer.disconnect()
    this.topSentinelTarget.remove()
    this.bottomSentinelTarget.remove()
  }

  reset() {
    this.createSentinels()
  }

  topSentinelTargetDisconnected() {
    if (this.hasTopSentinelTarget) return
    this.#addSentinel("top")
  }

  bottomSentinelTargetDisconnected() {
    if (this.hasBottomSentinelTarget) return
    this.#addSentinel("bottom")
  }

  createSentinels() {
    if (!this.hasTopSentinelTarget) this.#addSentinel("top")
    if (!this.hasBottomSentinelTarget) this.#addSentinel("bottom")
  }

  #addSentinel(position) {
    const sentinel = this.#createSentinel(position)
    if (position === "top") {
      this.element.prepend(sentinel)
    } else {
      this.element.appendChild(sentinel)
    }
    if (this.observer) this.observer.observe(sentinel)
  }

  #createSentinel(position) {
    const sentinel = document.createElement("div")
    sentinel.setAttribute("data-overflow-scroll-target", `${position}Sentinel`)
    return sentinel
  }

  #setupIntersectionObserver() {
    const options = {
      root: this.element,
      threshold: 1.0
    }

    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.target === this.topSentinelTarget) {
          this.element.classList.toggle(
            this.overflowTopClass,
            !entry.isIntersecting
          )
        } else if (entry.target === this.bottomSentinelTarget) {
          this.element.classList.toggle(
            this.overflowBottomClass,
            !entry.isIntersecting
          )
        }
      })
    }, options)

    this.observer.observe(this.topSentinelTarget)
    this.observer.observe(this.bottomSentinelTarget)
  }
}
