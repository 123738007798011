import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle", "content"]
  static values = { animateHeight: Boolean, collapseSiblings: Boolean }

  connect() {
    if (!this.animateHeightValue) return
    this.toggleSectionHeight(this.expanded)
  }

  disconnect() {
    this.close()
  }

  toggle() {
    if (!this.hasContentTarget) return
    const expanded = this.expanded
    const expanding = !expanded
    const hiding = expanded
    this.toggleTarget.setAttribute("aria-expanded", expanding)
    this.contentTarget.setAttribute("aria-hidden", hiding)

    if (this.animateHeightValue) this.toggleSectionHeight(expanding)
    if (expanding && this.collapseSiblingsValue) this.collapseSiblings()
  }

  toggled() {
    if (!this.hasContentTarget) return
    this.contentTarget.style.height = null
  }

  close() {
    if (!this.hasContentTarget) return
    this.toggleTarget.setAttribute("aria-expanded", false)
    this.contentTarget.setAttribute("aria-hidden", true)
    if (this.animateHeightValue) this.toggleSectionHeight(false)
  }

  collapseSiblings() {
    if (!this.hasContentTarget) return
    this.siblingControllers.forEach(siblingController => {
      if (siblingController && siblingController.expanded) {
        siblingController.toggle()
      }
    })
  }

  toggleSectionHeight(expand) {
    this.sectionHeight = this.getSectionHeight(expand)
  }

  getSectionHeight(expand) {
    if (expand) {
      return this.contentTarget.scrollHeight
    } else {
      return 0
    }
  }

  set sectionHeight(height) {
    if (!this.hasContentTarget) return
    this.contentTarget.style.height = `${height}px`
  }

  get expanded() {
    if (!this.hasToggleTarget) return
    return this.toggleTarget.getAttribute("aria-expanded") === "true" || false
  }

  get siblingControllers() {
    return [...this.siblings].map(sibling => {
      return this.application.getControllerForElementAndIdentifier(
        sibling,
        this.identifier
      )
    })
  }

  get siblings() {
    let siblings = []
    let sib = this.element.parentNode.firstChild
    while (sib) {
      if (sib.nodeType === 1 && sib !== this.element) {
        siblings.push(sib)
      }
      sib = sib.nextSibling
    }
    return siblings
  }
}
