import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select"]

  toggleOption({ params }) {
    const option = params.option
    if (this.selectTarget.value !== option) {
      this.selectTarget.value = option
    } else {
      this.selectTarget.value = ""
    }
    const changeEvent = new Event("change", { bubbles: true })
    this.selectTarget.dispatchEvent(changeEvent)
  }
}
