import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static values = {
    direction: String,
    changeDirectionPoint: Number,
    position: String
  }
  static targets = ["section"]
  static classes = [
    "hideInformation",
    "hideSearch",
    "open",
    "searchOpen",
    "shrunk",
    "stuck",
    "submenuVisible",
    "toggled",
    "toggling",
    "sectionHovered"
  ]

  initialize() {
    this.lastScroll = window.scrollY
    this.scheduledAnimationFrame = false
    this.shrinkPoint = 100
  }

  connect() {
    useClickOutside(this)
    if (window.scrollY > this.changeDirectionPointValue) this.directionBottom()
  }

  directionTop() {
    if (this.directionValue === "top") return
    this.directionValue = "top"
  }

  directionBottom() {
    if (this.directionValue === "bottom") return
    this.directionValue = "bottom"
  }

  stuck() {
    this.element.classList.add(this.stuckClass)
  }

  unstuck() {
    this.element.classList.remove(this.stuckClass)
  }

  hideSearch() {
    this.element.classList.add(this.hideSearchClass)
  }

  showSearch() {
    this.element.classList.remove(this.hideSearchClass)
  }

  hideInformation() {
    this.element.classList.add(this.hideInformationClass)
  }

  showInformation() {
    this.element.classList.remove(this.hideInformationClass)
  }

  onTableOfContentsSectionIn(event) {
    this.inViewSection = event.detail.sectionId
  }

  onTableOfContentsSectionOut(event) {
    if (!event.detail.sectionsInView.length) {
      this.inViewSection = null
    }
  }

  clickOutside() {
    this.close()
  }

  toggle() {
    this.element.classList.toggle(this.openClass)
    this.toggleBodyScroll()
  }

  open() {
    this.element.classList.add(this.openClass)
    this.toggleBodyScroll()
  }

  close() {
    this.element.classList.remove(this.openClass)
    this.toggleBodyScroll()
  }

  toggling() {
    this.element.classList.add(this.togglingClass)
    this.element.classList.remove(this.toggledClass)
  }

  toggled() {
    this.element.classList.remove(this.togglingClass)
    this.element.classList.add(this.toggledClass)
  }

  shrink() {
    if (!this.isSubmenuVisible) return
    if (!this.isPositionTop) return
    if (this.scheduledAnimationFrame) return
    const currentScroll = window.scrollY
    const isPastShrinkPoint = currentScroll > this.shrinkPoint
    const isAtTop = currentScroll <= 100
    const isScrollingDown = currentScroll > this.lastScroll
    this.scheduledAnimationFrame = true
    if (isScrollingDown && isPastShrinkPoint) {
      requestAnimationFrame(() => this.toggleShrunkClass(true))
    } else if (isAtTop) {
      requestAnimationFrame(() => this.toggleShrunkClass(false))
    }
    this.lastScroll = currentScroll
    this.scheduledAnimationFrame = false
  }

  toggleShrunkClass(toggle) {
    this.element.classList.toggle(this.shrunkClass, toggle)
  }

  onSearchToggle(event) {
    this.element.classList.toggle(this.searchOpenClass, event.detail.open)
  }

  toggleBodyScroll() {}

  set inViewSection(sectionInViewId) {
    this.data.set("inViewSection", sectionInViewId)
  }

  get isMobile() {
    return document.body.classList.contains("mobile")
  }

  get isOpen() {
    return this.element.classList.contains(this.openClass)
  }

  get isSubmenuVisible() {
    return this.element.classList.contains(this.submenuVisibleClass)
  }

  get isPositionTop() {
    return this.positionValue === "top"
  }

  onSectionMouseover(event) {
    const section = event.currentTarget
    if (section.classList.contains("MainMenuBlock-Section--active")) return
    this.element.classList.add(this.sectionHoveredClass)
  }

  onSectionMouseout(event) {
    const section = event.currentTarget
    if (section.classList.contains("MainMenuBlock-Section--active")) return
    this.element.classList.remove(this.sectionHoveredClass)
  }
}
