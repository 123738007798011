import { CarouselController } from "~/controllers/carousel_controller"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock-upgrade"

export default class extends CarouselController {
  options = {
    draggable: true,
    pageDots: false,
    prevNextButtons: true,
    wrapAround: true,
    arrowShape: {
      x0: 15,
      x1: 65,
      y1: 50,
      x2: 70,
      y2: 50,
      x3: 20
    }
  }

  connect() {
    disableBodyScroll(this.element)
    super.connect()
  }

  disconnect() {
    enableBodyScroll(this.element)
  }

  close() {
    this.element.parentNode.removeChild(this.element)
  }
}
