import { Controller } from "@hotwired/stimulus"
import { useScrollTo } from "~/controllers/mixins/useScrollTo"

export default class extends Controller {
  static targets = ["toggle", "content"]
  static values = {
    open: Boolean,
    collapseSiblings: Boolean,
    stickyHeader: Boolean,
    scrollToOnClose: Boolean,
    scrollToOnOpen: Boolean
  }
  static durableValues = ["open"]

  connect() {
    useScrollTo(this, { duration: 400 })
    if (this.isHashTarget) this.open()
  }

  disconnect() {
    this.close()
  }

  toggle() {
    this.openValue = !this.openValue
    if (this.isClosed) this.pauseVideos()
  }

  open() {
    this.openValue = true
  }

  close() {
    this.openValue = false
    this.pauseVideos()
  }

  openValueChanged() {
    this.toggleTarget.setAttribute("aria-expanded", this.openValue)
    this.contentTarget.setAttribute("aria-hidden", !this.openValue)
    if (this.openValue && this.collapseSiblingsValue) this.collapseSiblings()
  }

  collapseSiblings() {
    this.siblingControllers.forEach(siblingController => {
      if (siblingController) siblingController.close()
    })
  }

  onTransitionEnd(event) {
    if (event.target != this.contentTarget) return
    if (this.shouldScroll) this.scrollToTop()
  }

  pauseVideos() {
    for (const video of this.videos) {
      video.pause()
    }
  }

  get shouldScroll() {
    if (this.scrollToOnOpenValue && this.isOpen) return !this.isScrolledPast
    if (this.scrollToOnCloseValue && this.isClosed) return this.isScrolledPast
    if (this.isHashTarget) return true
  }

  get videos() {
    return this.element.getElementsByTagName("vm-player")
  }

  get isOpen() {
    return this.openValue
  }

  get isClosed() {
    return !this.openValue
  }

  get contentScrollHeight() {
    return this.contentTarget.scrollHeight
  }

  get siblingControllers() {
    return [...this.siblings].map(sibling => {
      return this.application.getControllerForElementAndIdentifier(
        sibling,
        this.identifier
      )
    })
  }

  get siblings() {
    let siblings = []
    let sib = this.element.parentNode.firstChild
    while (sib) {
      if (sib.nodeType === 1 && sib !== this.element) {
        siblings.push(sib)
      }
      sib = sib.nextSibling
    }
    return siblings
  }

  get isScrolledPast() {
    const { top } = this.element.getBoundingClientRect()
    return top <= 0
  }

  get isHashTarget() {
    if (!window.location.hash) return false
    return this.element.id === window.location.hash.substring(1)
  }
}
