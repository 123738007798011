import { Controller } from "@hotwired/stimulus"
import zenscroll from "zenscroll"

export default class extends Controller {
  static targets = ["more", "toggle"]

  initializer() {
    zenscroll.setup(200)
  }

  connect() {
    this.moreTarget.hidden = true
    this.toggleTarget.hidden = false
  }

  toggle() {
    this.toggleTarget.blur()
    this.moreTarget.hidden = !this.moreTarget.hidden
    this.toggleTarget.setAttribute(
      "aria-expanded",
      `${!this.moreTarget.hidden}`
    )
    if (this.isLess) this.scrollToTop()
  }

  scrollToTop() {
    zenscroll.setup(null, this.scrollOffset)
    zenscroll.to(this.element, 200)
  }

  get scrollOffset() {
    return 70
    // const style = this.computedStyle.getPropertyValue("scroll-margin-top")
    // return parseInt(style || 70, 10)
  }

  get isMore() {
    return !this.isLess
  }

  get isLess() {
    return this.moreTarget.hidden
  }

  get computedStyle() {
    return getComputedStyle(this.element)
  }
}
