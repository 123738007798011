import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["searchOpen"]

  connect() {
    this.#dispatchInViewEvent()
  }

  onScroll() {
    this.#dispatchInViewEvent()
  }

  onSearchToggle(event) {
    this.element.classList.toggle(this.searchOpenClass, event.detail.open)
  }

  #dispatchInViewEvent() {
    let eventType = this.outOfView ? "outview" : "inview"
    this.dispatch(eventType)
  }

  get outOfView() {
    return window.scrollY > this.element.offsetHeight + this.element.offsetTop
  }
}
