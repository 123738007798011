import Swiper from "swiper"
import { Navigation, Pagination } from "swiper/modules"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "amountOfSlides",
    "carousel",
    "currentSlideNumber",
    "pager",
    "slide",
    "pagerCurrent",
    "pagerTotal",
    "wrapper",
    "pagination",
    "nextButton",
    "prevButton"
  ]

  connect() {
    this.swiper = new Swiper(this.carouselTarget, {
      allowTouchMove: false,
      speed: 0,
      loop: true,
      modules: [Navigation, Pagination],
      pagination: {
        el: this.paginationTarget,
        type: "fraction",
        renderFraction: function(currentClass, totalClass) {
          return (
            '<span class="' +
            currentClass +
            '"></span>' +
            "/" +
            '<span class="' +
            totalClass +
            '"></span>'
          )
        }
      },
      navigation: {
        nextEl: this.nextButtonTarget,
        prevEl: this.prevButtonTarget
      }
    })
  }
}
