import { Controller } from "@hotwired/stimulus"
import Flickity from "flickity"
import "flickity-fade/flickity-fade.js"

export class CarouselController extends Controller {
  static values = {
    isFirstSlide: Boolean,
    isLastSlide: Boolean,
    initialIndex: Number,
    groupCells: Boolean,
    adaptiveHeight: Boolean,
    setGallerySize: Boolean,
    selectedIndex: Number,
    fade: Boolean,
    cellSelector: String
  }

  options = {
    draggable: false,
    pageDots: false,
    prevNextButtons: true,
    wrapAround: true,
    arrowShape: {
      x0: 15,
      x1: 65,
      y1: 50,
      x2: 70,
      y2: 50,
      x3: 20
    }
  }

  static targets = [
    "amountOfSlides",
    "carousel",
    "currentSlideNumber",
    "pager",
    "slide"
  ]

  connect() {
    this.initializeFlickity()
    if (this.flickity) {
      if (this.hasPagerTarget) this.pagerTarget.hidden = false
      this.currentSlideNumber = this.flickity.selectedIndex + 1
      this.amountOfSlides = this.flickity.slides.length
      this.toggleFirstLastSlideData()
    }
    this.resize()
  }

  disconnect() {
    if (this.flickity) this.flickity.destroy()
  }

  initializeFlickity() {
    if (!this.hasMultipleSlides) return
    const flickityOptions = Object.assign(
      {
        adaptiveHeight: this.adaptiveHeightValue,
        setGallerySize: this.setGallerySizeValue,
        fade: this.fadeValue,
        cellSelector: this.cellSelectorValue,
        groupCells: this.groupCellsValue,
        initialIndex: this.initialIndexValue
      },
      this.options
    )
    this.flickity = new Flickity(this.carouselTarget, flickityOptions)
    this.flickity.on("select", index => {
      this.onFlickitySelect(index)
    })
  }

  resize() {
    if (!this.flickity) return
    this.flickity.resize()
  }

  previous() {
    if (this.flickity) this.flickity.previous()
  }

  next() {
    if (this.flickity) this.flickity.next()
  }

  toggleFirstLastSlideData() {
    this.isFirstSlideValue = this.flickity.selectedIndex == 0
    this.isLastSlideValue =
      this.flickity.selectedIndex == this.slideTargets.length - 1
  }

  onFlickitySelect(index) {
    this.selectedIndexValue = index
    this.currentSlideNumber = index + 1
    this.toggleFirstLastSlideData()
  }

  get currentSlide() {
    return this.slideTargets[this.selectedIndex]
  }

  get hasMultipleSlides() {
    return this.slideTargets.length > 1
  }

  get selectedIndex() {
    return this.flickity.selectedIndex
  }

  set currentSlideNumber(number) {
    if (this.hasCurrentSlideNumberTarget) {
      this.currentSlideNumberTarget.textContent = number
    }
  }

  set amountOfSlides(amount) {
    if (this.hasAmountOfSlidesTarget) {
      this.amountOfSlidesTarget.textContent = amount
    }
  }
}
