import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static targets = ["queryInput"]

  connect() {
    useClickOutside(this)
  }

  toggle() {
    this.element.classList.toggle(this.openClass)
    if (this.isOpen) this.queryInputTarget.focus({ preventScroll: true })
    this.#dispatchToggleEvent()
  }

  clickOutside() {
    this.element.classList.remove(this.openClass)
    this.#dispatchToggleEvent()
  }

  #dispatchToggleEvent() {
    this.dispatch("toggle", { detail: { open: this.isOpen } })
  }

  get openClass() {
    return this.data.get("openClass")
  }

  get isOpen() {
    return this.element.classList.contains(this.openClass)
  }
}
