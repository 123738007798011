import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { open: { type: Boolean, default: false } }
  static classes = ["open"]

  openValueChanged() {
    this.element.classList.toggle(this.openClass, this.openValue)
  }

  toggle() {
    this.openValue = !this.openValue
    if (this.openValue) this.#collapseSiblings()
  }

  close() {
    this.openValue = false
  }

  #collapseSiblings() {
    this.#siblingControllers.forEach(siblingController => {
      if (siblingController) siblingController.close()
    })
  }

  get #siblingControllers() {
    return [...this.#siblings].map(sibling => {
      return this.application.getControllerForElementAndIdentifier(
        sibling,
        this.identifier
      )
    })
  }

  get #siblings() {
    let siblings = []
    let sib = this.element.parentNode.firstChild
    while (sib) {
      if (sib.nodeType === 1 && sib !== this.element) {
        siblings.push(sib)
      }
      sib = sib.nextSibling
    }
    return siblings
  }
}
