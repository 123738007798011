import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash"

export default class extends Controller {
  static targets = ["form", "fieldSelect"]

  connect() {
    this.submit = debounce(this.submit, 500).bind(this)
  }

  submit() {
    this.formTarget.requestSubmit()
  }

  toggleOption({ params }) {
    const { field, option } = params
    const select = this.fieldSelectTargets.find(f => f.dataset.field === field)
    const isMultipleSelect = select.multiple

    if (isMultipleSelect) {
      this.#toggleMultipleSelectOption(select, option)
    } else {
      this.#toggleSingleSelectOption(select, option)
    }

    this.submit()
  }

  #toggleSingleSelectOption(select, option) {
    if (select.value !== option.toString()) {
      select.value = option
    } else {
      select.value = ""
    }
  }

  #toggleMultipleSelectOption(select, option) {
    const selectOption = Array.from(select.options).find(
      o => o.value === option.toString()
    )
    selectOption.selected = !selectOption.selected
  }
}
