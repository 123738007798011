import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  values = { preloaded: Boolean }

  async preload() {
    if (this.#preloaded) return

    try {
      const response = await fetch(this.#url.toString(), {
        headers: { "VND.PREFETCH": "true", Accept: "text/html" }
      })
      await response.text()
      this.preloadedValue = true
    } catch (_) {
      // If we cannot preload that is ok!
    }
  }

  get #preloaded() {
    return this.preloadedValue
  }

  get #url() {
    this._url = this._url || new URL(this.element.href)
    return this._url
  }
}
