import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field"]
  static classes = ["hasQuery"]

  connect() {
    this.element.classList.toggle(this.hasQueryClass, this.#hasQuery)
  }

  onKeyup() {
    this.element.classList.toggle(this.hasQueryClass, this.#hasQuery)
  }

  clear() {
    this.fieldTarget.value = ""
    this.element.classList.remove(this.hasQueryClass)
  }

  get #hasQuery() {
    return this.fieldTarget.value.trim().length > 0
  }
}
