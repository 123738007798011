import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  unveiled() {
    this.element.classList.add(this.unveiledClass)
  }

  loaded() {
    this.element.classList.add(this.loadedClass)
  }

  get unveiledClass() {
    return this.data.get("unveiledClass")
  }

  get loadedClass() {
    return this.data.get("loadedClass")
  }
}
