import { Controller } from "@hotwired/stimulus"
import zenscroll from "zenscroll"

export default class extends Controller {
  connect() {
    zenscroll.setup(1000)
  }

  scroll(event) {
    event.preventDefault()
    const anchor = this._getAnchor(event.currentTarget.getAttribute("href"))
    const target = document.querySelector(anchor)
    if (!target) return
    const offset = this._getOffset(target)
    zenscroll.setup(null, offset)
    zenscroll.to(target)
  }

  _getOffset(target) {
    return parseInt(
      getComputedStyle(target).getPropertyValue("scroll-margin-top"),
      10
    )
  }

  _getAnchor(href) {
    const urlParts = href.split("#")
    return urlParts.length > 1 ? `#${urlParts[1]}` : null
  }
}
