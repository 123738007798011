import { Controller } from "@hotwired/stimulus"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock-upgrade"

export default class extends Controller {
  static targets = ["openSeaDragon"]
  static classes = ["minZoom", "maxZoom"]

  connect() {
    disableBodyScroll(this.element)
  }

  disconnect() {
    enableBodyScroll(this.element)
  }

  reset() {
    this.openSeaDragon.reset()
  }

  zoomIn() {
    this.openSeaDragon.zoomIn()
  }

  zoomOut() {
    this.openSeaDragon.zoomOut()
  }

  onZoom(event) {
    const { isMaxZoom, isMinZoom } = event.detail
    this.element.classList.toggle(this.minZoomClass, isMinZoom)
    this.element.classList.toggle(this.maxZoomClass, isMaxZoom)
  }

  close() {
    this.element.remove()
  }

  get openSeaDragon() {
    return this.openSeaDragonTarget[
      "architecture-of-territory--map-block--open-sea-dragon-component"
    ]
  }
}
