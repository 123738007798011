import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["slide"]
  static values = { index: Number, speed: Number }

  initialize() {
    this.interval = null
  }

  disconnect() {
    if (this.interval) clearInterval(this.interval)
  }

  start() {
    this.interval = setInterval(() => this.next(), this.speedValue)
  }

  stop() {
    if (this.interval) clearInterval(this.interval)
  }

  next() {
    if (this.indexValue === this.lastIndex) {
      this.indexValue = 0
    } else {
      this.indexValue++
    }
  }

  previous() {
    if (this.indexValue === 0) {
      this.indexValue = this.lastIndex
    } else {
      this.indexValue--
    }
  }

  indexValueChanged() {
    this.showCurrentSlide()
  }

  showCurrentSlide() {
    if (!this.hasSlideTarget) return
    this.slideTargets.forEach((element, index) => {
      element.dataset.hidden = index != this.indexValue
    })
  }

  get lastIndex() {
    return this.slideTargets.length - 1
  }
}
