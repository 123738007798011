import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.intersectionObserver = new IntersectionObserver(
      ([e]) => {
        this.toggleStuck(e.intersectionRatio < 1)
      },
      {
        rootMargin: "-1px 0px 0px 0px",
        threshold: [1]
      }
    )

    this.intersectionObserver.observe(this.element)
  }

  toggleStuck(isStuck) {
    const eventName = isStuck ? "stuck" : "unstuck"
    this.dispatch(eventName)
  }
}
