import zenscroll from "zenscroll"

export const useScrollTo = (controller, options = {}) => {
  Object.assign(controller, {
    scrollToTop(onDone) {
      zenscroll.setup(options.duration, this._getOffset())
      zenscroll.to(this.element, options.duration, onDone)
    },

    _getOffset() {
      return parseInt(
        getComputedStyle(this.element).getPropertyValue("scroll-margin-top"),
        0
      )
    }
  })
}
